import React, { Fragment } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import parse from "react-html-parser"
import data from "../data/privacity-policy-data"

const PrivacyPolicy = () => {
  const renderContent = () => {
    if (!data || !data.length) return

    return data.map((item, index) => {
      return (
        <Fragment key={index}>
          <TitleBlock
            title={item.title}
            className="PrivacyPolicy-subtitle"
            icon=""
          />
          <div className="PrivacyPolicy-text">{parse(item.content)}</div>
        </Fragment>
      )
    })
  }

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Política de privacidad" },
  ]

  return (
    <div className="LegalNotice">
      <Layout>
        <Hero image="/imgs/servicios-actividades-ret.jpg" text="Aviso legal" />
        <div className="container950">
          <Breadcrumb path={path} className="Assosiation-breadcrumb" />
          <TitleBlock
            title="Política de privacidad"
            className="PrivacyPolicy-title"
            icon="fas fa-pen-square"
          />
          {renderContent()}
        </div>
      </Layout>
    </div>
  )
}

export default PrivacyPolicy
